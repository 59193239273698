.scroll-down-button {
    position: fixed;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 8px;
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
    z-index: 9999;
  }
    
  .scroll-down-button:focus {
    outline: none;
  }
  
  @keyframes pulse {
    0% {
      transform: translate(-50%, 0) scale(1);
    }
    50% {
      transform: translate(-50%, 4px) scale(1.05);
    }
    100% {
      transform: translate(-50%, 0) scale(1);
    }
  }
  
  .scroll-down-button.pulse {
    animation: pulse 1s ease-in-out;
  }