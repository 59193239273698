:root {
  --primary-color: #0077b5;
  --primary-light: #0a8cd2;
  --accent-color: #00a0dc;
  --bg-dark: #121212;
  --bg-card: #1e1e1e;
  --text-light: #ffffff;
  --text-muted: #b0b0b0;
  --border-color: #2a2a2a;
  --transition-speed: 0.3s;
}

body {
  background-color: var(--bg-dark);
  color: var(--text-light);
  font-family: 'Playfair Display';
  line-height: 1.6;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.years {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: 50px;
  overflow: hidden;
  background-color: var(--bg-card);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow var(--transition-speed) ease;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.years:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.years li {
  display: inline-block;
  color: var(--text-light);
  font-size: 1rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
  border-right: 1px solid var(--border-color);
  position: relative;
  overflow: hidden;
}

.years li::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.6s ease;
}

.years li:hover::before {
  left: 100%;
}

.years li.is-active {
  background-color: var(--primary-color);
  color: var(--text-light);
  font-weight: 600;
}

.tabs .years li:hover {
  background-color: var(--primary-light);
  transform: translateY(-2px);
}

.years li:last-child {
  border-right: none;
}

.careers-container {
  width: 80%;
  margin: 0 auto 2rem auto;
  background-color: var(--bg-card);
  padding: 2.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: box-shadow var(--transition-speed) ease;
}

.careers-container:hover {
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.careers-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  margin: 0 !important;
}

.careers-table th,
.careers-table td {
  padding: 1.2rem;
  text-align: left;
  word-wrap: break-word;
  border-bottom: 1px solid var(--border-color);
  transition: background-color var(--transition-speed) ease;
}

.careers-table th {
  background-color: var(--primary-color);
  color: var(--text-light);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
}

.careers-table th::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--accent-color);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.careers-table th:hover::after {
  transform: scaleX(1);
}

.careers-table tbody tr {
  transition: all var(--transition-speed) ease;
}

.careers-table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.05);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.careers-table tbody tr:last-child td {
  border-bottom: none;
}

.subtitle {
  font-weight: 600;
  margin-top: 1.2rem;
  display: block;
  font-size: 1.25rem;
  color: var(--accent-color);
  width: 100%;
  transition: color var(--transition-speed) ease;
}

.subtitle:hover {
  color: var(--primary-light);
}

.careers-table td:nth-child(1),
.careers-table td:nth-child(2),
.careers-table td:nth-child(3) {
  max-width: 20rem;
  white-space: normal;
}

@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  .tabs {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .years {
    flex-direction: column;
    width: 100%;
    border-radius: 12px;
  }

  .years li {
    padding: 1rem;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
    text-align: center;
  }

  .years li:last-child {
    border-bottom: none;
  }

  .careers-container {
    width: 95%;
    padding: 1.5rem;
  }

  .careers-table,
  .careers-table tbody,
  .careers-table tr {
    display: block;
    width: 100%;
  }

  .careers-table thead {
    display: none;
  }

  .careers-table tr {
    margin-bottom: 1.5rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.02);
    transition: all var(--transition-speed) ease;
  }

  .careers-table tr:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .careers-table td {
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
  }

  .careers-table td::before {
    content: attr(data-label);
    font-weight: 600;
    text-align: left;
    margin-bottom: 0.5rem;
    color: var(--accent-color);
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .careers-table td:last-child {
    border-bottom: none;
  }

  .subtitle {
    font-size: 1.1rem;
    margin: 0.75rem 0;
    text-align: left;
  }

  .careers-table td,
  .careers-table td:nth-child(1),
  .careers-table td:nth-child(2),
  .careers-table td:nth-child(3) {
    max-width: none;
    white-space: normal;
    word-wrap: break-word;
  }
}