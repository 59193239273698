table {
    border-collapse: collapse;
    width: 100%;
    font-family: sans-serif;
    font-size: 18px;
    color: #ffffff;
}

tr {
    font-family: sans-serif;
}

td {
    padding: 12px;
    font-family: sans-serif;
}
