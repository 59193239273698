.careersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.careerstitleSection {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.3); /* Optional: Adds a dark overlay for better text visibility */
  box-sizing: border-box;
  scroll-snap-align: start;
}

.careersmainTitleSection {
  width: 100%;
  padding: 50px 20px;
  background-color: #121212;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
}

.careersmainTitle {
  font-size: 3rem;
  font-family: 'Playfair Display';
  color: rgb(255, 255, 255);
  text-align: center;
  margin: 0;
}

.careersContent {
  position: relative;
  z-index: 2;
}


.careers-subtitle1 {
  font-family: 'Greek Font', sans-serif;
  font-size: 24px;
  margin: 0;
}

.careers-section {
  font-family: 'Greek Font', sans-serif;
  font-size: 24px;
  margin: 0;
  background: #121212;
  margin: -1rem auto;
}

.table {
  margin: 5rem auto;
  border-radius: 2rem;
  border: 1rem hidden blue;
  border-collapse: collapse;
  box-shadow:rgba(0, 0, 0, 0.6)
}

.thead {
  font-size: 10px;
  background-color: blue;
  color: white;
}

.th, .td {
  border: 1px solid #dbdbdb;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}

.td {
  font-size: 1.5rem;
}
