.nav-links {
  list-style: none;
  display: flex;
  gap: 25px;
  margin: 0;
  padding: 0;
  color: #121212
}

.nav-links li {
  position: relative;
  padding: 5px;
}

.nav-links li .NavLink {
  text-decoration: none;
  color: aliceblue;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-shadow: 1px 1px 2px black;
}

.nav-links li .NavLink:hover {
  color: aliceblue;
  transform: translateY(-3px);
}

.nav-links li .NavLink.active {
  color: rgb(255, 255, 255);
}

/* Standard underline effect for all NavLinks */
.nav-links li .NavLink::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #0077b5;
  left: 0;
  bottom: -6px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.nav-links li .NavLink:hover::after,
.nav-links li .NavLink.active::after {
  transform: scaleX(1);
}

.nav-links li .NavLink.active:hover::after {
  background-color: #302ad6;
  backdrop-filter: blur(70px);
}

/* Special styling for Rush NavLink */
.nav-links li:last-child .NavLink {
  color: #ffd700; /* Golden text color */
  animation: rushGlow 3s infinite ease-in-out;
  font-weight: 600; /* Slightly bolder */
  position: relative;
  letter-spacing: 0.03em; /* Slightly wider letter spacing */
}

.nav-links li:last-child .NavLink::after {
  background: linear-gradient(90deg, #ffd700, #ffc800, #ffd700);
  height: 3px;
}

.nav-links li:last-child .NavLink:hover::after,
.nav-links li:last-child .NavLink.active::after {
  transform: scaleX(1);
}

@keyframes rushGlow {
  0% {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7), 0 0 10px rgba(255, 215, 0, 0.3);
  }
  25% {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7), 0 0 20px rgba(255, 215, 0, 0.7);
  }
  50% {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7), 0 0 30px rgba(255, 215, 0, 1);
  }
  75% {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7), 0 0 20px rgba(255, 215, 0, 0.7);
  }
  100% {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7), 0 0 10px rgba(255, 215, 0, 0.3);
  }
}

/* Mobile styles */
.hamburger span {
  width: 25px;
  height: 3px;
  background-color: #ffffff;
  margin-bottom: 4px;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .nav-links.mobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(0.75rem);
    padding: 1rem;
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.4),
                0 0 15px rgba(255, 215, 0, 0.2); /* Slight golden glow */
  }

  .nav-links.mobile li {
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-around;
  }
  
  /* Ensure the Rush NavLink still stands out on mobile */
  .nav-links.mobile li:last-child {
    margin-top: 12px;
    padding-top: 8px;
    border-top: 1px solid rgba(255, 215, 0, 0.5);
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    padding: 0 1.4rem;
  }
}
