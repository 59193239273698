.navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  