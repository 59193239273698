/* FAQ Section Container */
.faq-section {
  padding: 5rem 1rem;
  color: var(--color-text);
  text-align: center;
}

/* Header Styling */
.faq-header h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: var(--color-accent);
  font-weight: 700;
  letter-spacing: 1px;
}

.faq-header p {
  font-size: 1.2rem;
  color: var(--color-text-muted);
  margin-bottom: 40px;
}

/* FAQ Container */
.faq-container {
  max-width: 80%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .faq-container {
    max-width: 95%;
  }
}

/* FAQ Card Style */
.faq-item {
  background: var(--color-background-light);
  border-radius: 12px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.25s ease, box-shadow 0.25s ease;
}

.faq-item.active {
  transform: translateY(-3px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* FAQ Question Button */
.faq-question {
  width: 100%;
  padding: 20px;
  background: none;
  border: none;
  outline: none;
  text-align: left;
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-text);
  transition: background-color 0.25s ease;
}


/* FAQ Icon */
.faq-icon {
  font-size: 1.8rem;
  transition: transform 0.25s ease;
  color: var(--color-accent);
}

/* FAQ Answer (Collapsible) */
.faq-answer {
  max-height: 0;
  opacity: 0;
  padding: 0 20px;
  overflow: hidden;
  transition: max-height 0.1s ease, opacity 0.2s ease, padding 0.2s ease;
}

.faq-item.active .faq-answer {
  padding: 20px;
  max-height: 500px; /* Set to a value that is higher than the expected content height */
  opacity: 1;
}

.faq-answer p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.6;
  color: var(--color-text-muted);
}
