body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  /* background-color: #0f0f0f; */
  font-family: 'Palatino Linotype';
}

.recruitmentContainer {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  position: relative;
  background-image: url('../Assets/Frames/SPRING\ RUSH\ 25\ GRAPHIC\ DESIGN.webp');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.recruitmentContainer::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, 
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  pointer-events: none;
  z-index: 1;
}

.viewport {
  height: 100vh;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: silver;
  text-shadow: 2px 2px 5px black;
  padding: 0rem 2rem;
  position: relative;
  z-index: 2;
}

.hero-recruitment-Section {
  text-align: center;
  border-radius: 10px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  padding: 2rem;
}

.hero-recruitment-Section::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 1px solid rgba(192, 192, 192, 0.1);
  border-radius: 15px;
  animation: borderPulse 5s ease-in-out infinite;
}

@keyframes borderPulse {
  0%, 100% {
    border-color: rgba(192, 192, 192, 0.1);
    transform: scale(1);
  }
  50% {
    border-color: rgba(192, 192, 192, 0.3);
    transform: scale(1.02);
  }
}

@keyframes titleGradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.main-recruitment-Title {
  font-size: 6rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  position: relative;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.3);
  background: linear-gradient(45deg, #ffffff 0%, #e0e0e0 20%, #ffffff 40%, #c0c0c0 60%, #ffffff 80%, #e0e0e0 100%);
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: titleGradient 8s linear infinite, titleFloat 2s ease-in-out infinite, titleHoverGlow 5s ease-in-out, titleGlow 3s ease-in-out infinite;
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.3)) drop-shadow(0 0 20px rgba(255, 255, 255, 0.2));
}


@keyframes titleHoverGlow {
  0%, 100% { text-shadow: 0px 0px 12px rgba(255, 255, 255, 0.5); }
  50% { text-shadow: 0px 0px 25px rgba(255, 255, 255, 0.8); }
}


@keyframes titleGlow {
  0% { text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.2); }
  50% { text-shadow: 0px 0px 25px rgba(255, 255, 255, 0.8); }
  100% { text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.2); }
}


/* Media queries for responsiveness */
@media (max-width: 768px) {
  .main-recruitment-Title {
    font-size: 3.5rem;
  }
}

@media (min-width: 1200px) {
  .main-recruitment-Title {
    font-size: 7rem;
  }
}

@keyframes titleReveal {
  0% {
    opacity: 1;
    transform: scale(1) translateY(0);
    filter: blur(0);
  }
  50% {
    opacity: 0;
    transform: scale(0.9) translateY(30px);
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
    filter: blur(0);
  }
}

@keyframes titleGradient {
  0% {
    background-position: 0% center;
  }
  100% {
    background-position: 200% center;
  }
}

@keyframes glitchText {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(2px, 2px);
  }
  80% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
  }
}

.subTitle {
  font-size: 2rem;
  margin-bottom: 2rem;
  opacity: 0;
  animation: fadeIn 1.1s ease-out forwards 0.2s;
  color: rgba(192, 192, 192, 0.9);
  position: relative;
}

.subTitle::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(192, 192, 192, 0.5),
    transparent
  );
  animation: lineExpand 1.5s ease-out forwards 2s;
}

@keyframes lineExpand {
  0% {
    width: 0;
  }
  100% {
    width: 80%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
    filter: blur(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0);
  }
}

.eventSection {
  opacity: 0;
  animation: sectionFadeIn 1s ease-out forwards 2s;
}

@keyframes sectionFadeIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.eventSection h2 {
  font-size: 3rem;
  color: silver;
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.6);
  position: relative;
  overflow: hidden;
}

.eventSection h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(192, 192, 192, 0.5),
    transparent
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.eventSection p {
  font-size: 1.2rem;
  color: rgba(200, 200, 200, 0.9);
  line-height: 1.6;
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .main-recruitment-Title {
    font-size: 3.5rem;
  }
  
  .subTitle {
    font-size: 1.5rem;
  }
  
  .eventSection h2 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .main-recruitment-Title {
    font-size: 7rem;
  }
  
  .subTitle {
    font-size: 2.5rem;
  }
}

.rush-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.calendar-button {
  display: inline-block;
  padding: 12px 28px;
  background-color: rgba(255, 255, 255, 0.1);
  color: silver;
  text-decoration: none;
  border: 1px solid rgba(192, 192, 192, 0.4);
  border-radius: 4px;
  font-family: 'Palatino Linotype', 'Times New Roman', serif;
  font-size: 1rem;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  margin-bottom: 0.5rem;
}

.calendar-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(192, 192, 192, 0.6);
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .rush-buttons {
    width: 100%;
    max-width: 300px;
  }
  
  .calendar-button {
    width: 100%;
    text-align: center;
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}