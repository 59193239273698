.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #121212;
}

.not-found-card {
    max-width: 28rem;
    width: 100%;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    overflow: hidden;
}

.not-found-content {
    padding: 1rem;
}

.not-found-image {
    width: 16rem;
    height: auto;
    object-fit: cover;
    margin: 0 auto 1rem;
    display: block;
}

.not-found-title {
    font-size: 2.25rem;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0.5rem;
}

.not-found-message {
    font-size: 1.25rem;
    text-align: center;
    color: #ffffff;
}

.not-found-submessage {
    text-align: center;
    color: #6b7280;
    margin-top: 0.5rem;
}

.not-found-action {
    padding: 0.75rem 1rem;
}

.not-found-button {
    display: block;
    width: 100%;
    text-align: center;
    background-color: #3b82f6;
    color: white;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    text-decoration: none;
}

.not-found-button:hover {
    background-color: #2563eb;
}