.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: transparent; 
  z-index: 100;
  position: absolute!important; 
  top: 0;
  width: 100%;
  box-sizing: border-box;
}